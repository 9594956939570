import { Box, Button, Divider, RadioGroup, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Fade,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import CustomButton from "../../Button";
import conservative from "../../../assets/Risk_Profile_Gauge/1_Conservative.png";
import moderate from "../../../assets/Risk_Profile_Gauge/3_Moderate.png";
import aggressive from "../../../assets/Risk_Profile_Gauge/5_Aggressive.png";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "10px",
  },
  ml10px: {
    marginLeft: "40px",
  },
  mb30px: {
    marginTop: "60px",
    marginBottom: "60px",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
      </Box>
    </>
  );
}
const CUSTOM_STATE = {
  progress: 0,
  currentQuestionIndex: 0,
  nextItem: 0,
  checked: true,
};
const RiskProfiler = (props) => {
  const { riskProfile, riskType} = props;
  const [customState, setCustomState] = useState(CUSTOM_STATE);

  useEffect(() => {
    progress();
    const {
      selectedIncomeStability,
      selectedInstrumentsInvested,
      selectedInvestmentFamiliarity,
      selectedInvestmentPriority,
      selectedInvestmentType,
      selectedPeriodWithoutRedemption,
      selectedPortfolioComfort,
      selectedWillingRisk,
      selectedYearsUntilRetirement,
    } = riskProfile;

    const length = Object.keys({
      selectedIncomeStability,
      selectedInstrumentsInvested,
      selectedInvestmentFamiliarity,
      selectedInvestmentPriority,
      selectedInvestmentType,
      selectedPeriodWithoutRedemption,
      selectedPortfolioComfort,
      selectedWillingRisk,
      selectedYearsUntilRetirement,
    }).length;

    let count = 0;
    if (selectedIncomeStability) count++;
    if (selectedInstrumentsInvested) count++;
    if (selectedInvestmentFamiliarity) count++;
    if (selectedInvestmentPriority) count++;
    if (selectedInvestmentType) count++;
    if (selectedPeriodWithoutRedemption) count++;
    if (selectedPortfolioComfort) count++;
    if (selectedWillingRisk) count++;
    if (selectedYearsUntilRetirement) count++;
    setCustomState((prevState) => ({
      ...prevState,
      progress: (count / length) * 100,
      currentQuestionIndex: count !== 0 ? count - 1 : count,
    }));
  }, []);

    useEffect(() => {
      progress();
    }, [
      riskProfile.selectedIncomeStability,
      riskProfile.selectedInstrumentsInvested,
      riskProfile.selectedInvestmentFamiliarity,
      riskProfile.selectedInvestmentPriority,
      riskProfile.selectedInvestmentType,
      riskProfile.selectedPeriodWithoutRedemption,
      riskProfile.selectedPortfolioComfort,
      riskProfile.selectedWillingRisk,
      riskProfile.selectedYearsUntilRetirement,
    ]);


  const progress = () => {
    const {
      selectedIncomeStability,
      selectedInstrumentsInvested,
      selectedInvestmentFamiliarity,
      selectedInvestmentPriority,
      selectedInvestmentType,
      selectedPeriodWithoutRedemption,
      selectedPortfolioComfort,
      selectedWillingRisk,
      selectedYearsUntilRetirement,
    } = riskProfile;

    const length = Object.keys({
      selectedIncomeStability,
      selectedInstrumentsInvested,
      selectedInvestmentFamiliarity,
      selectedInvestmentPriority,
      selectedInvestmentType,
      selectedPeriodWithoutRedemption,
      selectedPortfolioComfort,
      selectedWillingRisk,
      selectedYearsUntilRetirement,
    }).length;

    let count = 0;
    if (selectedIncomeStability) count++;
    if (selectedInstrumentsInvested) count++;
    if (selectedInvestmentFamiliarity) count++;
    if (selectedInvestmentPriority) count++;
    if (selectedInvestmentType) count++;
    if (selectedPeriodWithoutRedemption) count++;
    if (selectedPortfolioComfort) count++;
    if (selectedWillingRisk) count++;
    if (selectedYearsUntilRetirement) count++;

    setCustomState((prevState) => ({
      ...prevState,
      progress: (count / length) * 100,
      nextItem: count === 0,
    }));
  };

  const continueData = (e) => {
    e.preventDefault();
     props.nextStep();
     props.fixedStep(3);
  };

  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
console.log(customState)
  const handleAutonext = () => {

    const isRequired=   !riskProfile.selectedIncomeStability &&
    !riskProfile.selectedInstrumentsInvested &&
    !riskProfile.selectedInvestmentFamiliarity &&
    !riskProfile.selectedInvestmentPriority &&
    !riskProfile.selectedInvestmentType &&
    !riskProfile.selectedPeriodWithoutRedemption &&
    !riskProfile.selectedPortfolioComfort &&
    !riskProfile.selectedWillingRisk &&
    !riskProfile.selectedYearsUntilRetirement

      setCustomState((prevState) => ({
        ...prevState,
        checked: false,
      }));
       if (customState.currentQuestionIndex === 8) {
         setCustomState((prevState) => ({
           ...prevState,
           checked: true,
         }));
       } else {
         setTimeout(() => {
           setCustomState((prevState) => ({
             ...prevState,
             checked: true,
             currentQuestionIndex: isRequired ? 1 :prevState.currentQuestionIndex + 1,
           }));
         }, 200);
       }

  }
  const risk = props.computeRiskProfile(riskProfile);
      if (riskType.riskProfileType
 !== risk) {
        console.log(riskType.riskProfileType !== risk);
        props.handleRiskMessage(risk);
      }
  const questions = [
    {
      text: "1. Which of the following instruments have you invested in?",
      selected: riskProfile.selectedInstrumentsInvested,
      target: "selectedInstrumentsInvested",
      a: "Time Deposits, Money Market Funds",
      b: "Government Securities, Corporate Bonds, Peso/Dollar Bond Funds (MF and UITF)",
      c: "Direct Stocks, Equity Mutual Funds/UITFs, Derivatives",
    },
    {
      text: "2. What's your priority in investing?",
      selected: riskProfile.selectedInvestmentPriority,
      target: "selectedInvestmentPriority",
      a: "Capital Preservation",
      b: "Income Generation but with Capital Protection",
      c: "Capital Appreciation",
    },
    {
      text: "3. How familiar are you with different types of investments?",
      selected: riskProfile.selectedInvestmentFamiliarity,
      target: "selectedInvestmentFamiliarity",
      a: "My knowledge is limited",
      b: "My knowledge is average",
      c: "My knowledge is extensive",
    },
    {
      text: "4. Your current investments are mostly in which financial instrument?",
      selected: riskProfile.selectedInvestmentType,
      target: "selectedInvestmentType",
      a: "Time Deposits, Money Market Funds",
      b: "Government Securities, Corporate Bonds, Peso/Dollar Bond Funds (MF and UITF)",
      c: "Direct Stocks, Equity Mutual Funds/UITFs, Derivatives",
    },
    {
      text: "5. How stable are your finances?",
      selected: riskProfile.selectedIncomeStability,
      target: "selectedIncomeStability",
      a: "Somewhat Stable",
      b: "Stable but seeing major expenses in 3 years",
      c: "Very stable for the next 3 years",
    },
    {
      text: "6. How many years can you invest without redeeming your funds?",
      selected: riskProfile.selectedPeriodWithoutRedemption,
      target: "selectedPeriodWithoutRedemption",
      a: "1 year",
      b: "3-5 years",
      c: "More than 5 years",
    },
    {
      text: "7. How many years until your retirement?",
      selected: riskProfile.selectedYearsUntilRetirement,
      target: "selectedYearsUntilRetirement",
      a: "1 year -7 years",
      b: "8-15 years",
      c: "More than 15 years",
    },
    {
      text: "8. How much risk are you willing to take?",
      selected: riskProfile.selectedWillingRisk,
      target: "selectedWillingRisk",
      a: "Minimal Risk",
      b: "Moderate Risk",
      c: "High Risk",
    },
    {
      text: "9.  What portfolio are you most comfortable with?",
      selected: riskProfile.selectedPortfolioComfort,
      target: "selectedPortfolioComfort",
      a: "Highest return of 10%, biggest loss of 5%",
      b: "Highest return of 40%, biggest loss of 30%",
      c: "Highest return of 60%, biggest loss of 50%",
    },
  ];
  const selectedQ = questions[customState.currentQuestionIndex];

  const speed = 300;
  const questionList = (
    <>
      {!selectedQ ? (
        <div className=" rounded-lg shadow-2xl mx-auto lg:-mx-12 py-5 lg:py-10 px-5 lg:px-10 text-center  ">
          <p className=" text-base font-medium capitalize">
            Your are {riskType.riskProfileType
 === "aggressive" ? "an" : "a"}{" "}
            {riskType.riskProfileType
}-type investor!{" "}
          </p>

          <div className=" font-poppins text-2xl font-bold capitalize ">
            {riskType.riskProfileType
}
          </div>

          <img
            src={
              riskType.riskProfileType
 === "conservative"
                ? conservative
                : riskType.riskProfileType
 === "moderate"
                ? moderate
                : aggressive
            }
            alt="check"
            className="w-40 md:w-80 lg:w-80"
          />
          <p className=" font-poppins text-base">
            {riskType.riskProfileType
 === "conservative"
              ? "You protect your money while giving it space to grow through low-risk investment funds. you are an investor with a low appetite for risk and you are amenable to modest returns. You embrace the concept of “slowly but surely”."
              : riskType.riskProfileType
 === "moderate"
              ? "As a moderately aggressive investor, you want the balance of capital growth and protection. You want to experience the “best of both worlds”."
              : "As an aggressive investor, you are committed to investing for the long term, knowing that you may encounter market downturns along the way. You have set aside enough for your short-term needs, and you have enough money for investing."}
          </p>
          <p className=" font-poppins text-base lg:mx-20">
            {riskType.riskProfileType
 === "conservative"
              ? "You have money lying around but might need it anytime soon, so your investing horizon is closer towards the short-term."
              : riskType.riskProfileType
 === "moderate"
              ? "You prefer the combined benefits of bonds and stocks as a way to reach your goals while building your financial nest for the long-term."
              : "You want to increase the earning potential of your money for maximum profit to meet your goals."}
          </p>
          <div
            className="mt-10 font-poppins text-sm my-5 font-medium italic"
            style={{ textAlign: "left" }}
          >
            {riskType.riskProfileType
 === "conservative" ? (
              <>
                <h4>Recommended Funds for You: </h4>
                <p>&#x2022; Bond Funds</p>
                <p>&#x2022; Money Market Funds</p>
              </>
            ) : riskType.riskProfileType
 === "moderate" ? (
              <>
                <h4>Recommended Funds for You: </h4>
                <p>&#x2022; Balanced Funds (Equities & Fixed Income)</p>
              </>
            ) : (
              <>
                <h4>Recommended Funds for You: </h4>
                <p>&#x2022; Stock or Equity Funds</p>
                <p>&#x2022; Index Funds</p>
                <p>&#x2022; Alpha Funds </p>
                <p>&#x2022; Strategic Growth Funds</p>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="text-center w-full my-3 lg:mt-12">
            <label
              style={{
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "27px",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              {selectedQ.text}
            </label>
          </div>

          <Fade in={customState.checked} timeout={speed}>
            <Grid item xs={12} lg={12} container justifyContent="center">
              <div className="w-full lg:w-2/3 ">
                <RadioGroup
                  name={selectedQ.target}
                  value={
                    selectedQ.selected !== ""
                      ? parseInt(selectedQ.selected)
                      : selectedQ.selected
                  }
                  onChange={props.handleMainChange(selectedQ.target)}
                >
                  <FormControlLabel
                    className={`my-2 text-base lg:my-4 py-0 lg:py-2 bg-gray-100 rounded border-solid w-full  ${
                      selectedQ.selected == 1 && " bg-primary text-white"
                    }`}
                    value={1}
                    style={{ marginLeft: "8px", borderColor: "#c8932a" }}
                    control={
                      <Radio
                        onClick={handleAutonext}
                        style={{ visibility: "hidden" }}
                      />
                    }
                    label={
                      <strong style={{ fontSize: "16px" }}>
                        {selectedQ.a}
                      </strong>
                    }
                  />
                  <FormControlLabel
                    className={`my-2  text-base lg:my-4 py-0 lg:py-2 bg-gray-100 border-gray-300 rounded border-solid w-full  ${
                      selectedQ.selected == 2 && " bg-primary text-white"
                    }`}
                    value={2}
                    style={{ marginLeft: "8px", borderColor: "#c8932a" }}
                    control={
                      <Radio
                        onClick={handleAutonext}
                        style={{ visibility: "hidden" }}
                      />
                    }
                    label={
                      <strong style={{ fontSize: "16px" }}>
                        {selectedQ.b}
                      </strong>
                    }
                  />
                  <FormControlLabel
                    className={`my-2  lg:my-4 py-0 lg:py-2 bg-gray-100 border-gray-300 rounded border-solid w-full  ${
                      selectedQ.selected == 3 && " bg-primary text-white"
                    }`}
                    value={3}
                    style={{ marginLeft: "8px", borderColor: "#c8932a" }}
                    control={
                      <Radio
                        onClick={handleAutonext}
                        style={{ visibility: "hidden" }}
                      />
                    }
                    label={
                      <strong style={{ fontSize: "16px" }}>
                        {selectedQ.c}
                      </strong>
                    }
                  />
                </RadioGroup>
              </div>
            </Grid>
          </Fade>
          {/* </div> */}
        </>
      )}
    </>
  );

  return (
    <>
      <Fade in={true}>
        <div className="px-3 lg:px-20">
          <form onSubmit={continueData}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h1 className=" relative text-center left-0 ">Risk Profiler</h1>
                {selectedQ && (
                  <>
                    <div className="text-center text-base font-normal font-poppins ">
                      {" "}
                      This Risk Profiler quiz will help us assess your investing
                      preferences and risk tolerance level for us to recommend
                      funds that suit your profile. Please answer each question
                      to the best of your knowledge.{" "}
                    </div>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {selectedQ && (
                  <div className=" bg-gray-100 py-4 px-2 rounded">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="text-sm font-bold">Progress</span>
                      <Box minWidth={35} style={{ textAlign: "right" }}>
                        <span
                          style={{
                            backgroundColor: "#256141",
                            borderRadius: "10px",
                            padding: "2px 4px 2px 4px",
                            margin: "4px",
                            display: "inline-block",
                            color: "white",
                          }}
                        >
                          <Typography color="inherit">{`${Math.round(
                            customState.progress
                          )}%`}</Typography>
                        </span>
                      </Box>
                    </div>

                    <LinearProgressWithLabel
                      color="primary"
                      value={customState.progress}
                    />
                  </div>
                )}
              </Grid>
              <Divider />
              {questionList}
              <Grid item xs={12} lg={12} container justify="center">
                {selectedQ && (
                  <div className=" w-full lg:w-2/3 lg:flex lg:justify-center lg:align-middle">
                    {customState.currentQuestionIndex > 0 && (
                      <Grid item xs={12}>
                        <br />
                        <Button
                          variant="outlined"
                          color="secondary"
                          className="w-full 2xl:w-56 xl:w-60 lg:w-40  left-2 lg:left-2"
                          style={{ border: "2px solid #246040" }}
                          onClick={() => {
                            setCustomState((prevState) => ({
                              ...prevState,
                              currentQuestionIndex:
                                prevState.currentQuestionIndex - 1,
                            }));
                          }}
                        >
                          Previous
                        </Button>
                      </Grid>
                    )}
                    {customState.currentQuestionIndex === 0 && (
                      <Grid item xs={12}>
                        <br />

                        <div className="btn-back" onClick={back}>
                          Previous Step
                        </div>
                      </Grid>
                    )}
                    {questions.length - 1 ===
                      customState.currentQuestionIndex && (
                      <Grid item xs={12}>
                        <span className="relative grid  my-5 lg:my-0 lg:justify-items-end ">
                          <Button
                            variant="contained"
                            disabled={Math.round(customState.progress) !== 100}
                            color="primary"
                            style={{ color: "white" }}
                            className={`w-full 2xl:w-56 xl:w-60 lg:w-40 left-2 lg:left-0 top-0 lg:top-4  `}
                            onClick={() =>
                              setCustomState((prevState) => ({
                                ...prevState,
                                currentQuestionIndex:
                                  prevState.currentQuestionIndex + 1,
                              }))
                            }
                          >
                            View Result
                          </Button>
                        </span>
                      </Grid>
                    )}
                  </div>
                )}
              </Grid>

              <Divider />
              {!selectedQ && (
                <Grid style={{ marginTop: "30px" }} item xs={12}>
                  <div className="flex">
                    <div
                      className="btn-back"
                      onClick={() => {
                        setCustomState((prevState) => ({
                          ...prevState,
                          currentQuestionIndex:
                            prevState.currentQuestionIndex - 1,
                        }));
                      }}
                    >
                      Previous Step
                    </div>
                    <CustomButton
                      text="Continue"
                      type="submit"
                      disabled={riskProfile.loading}
                      buttonTheme="primary"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </Fade>
    </>
  );
};



export default RiskProfiler;


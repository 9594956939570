import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Fade,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useForm } from "react-hook-form";

import CustomButton from "../../Button";
import Helpers from "../../Helpers";
import { optionSelectedID, optionSelectedAnnualIncome, natureOfBusinessList } from "./constants";
import COUNTRIES from "../../PRPCM/Countries";
import {
  SelectForm,
  SelectFormAddress,
  TextForm,
  editIdPhotoBackSwitch,
  editIdPhotoFrontSwitch,
} from "../shortKYC/fieldAndimage";
import PROVINCES from "../../PRPCM/Provinces";
import CITIES from "../../PRPCM/Cities";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "10px",
  },
  ml10px: {
    marginLeft: "40px",
  },
  mb30px: {
    marginTop: "60px",
    marginBottom: "60px",
  },
  drop: {
    marginTop: "9px",
    height: "45px",
  },
}));

const ClientInformation = (props) => {
  const { additionalInfo, initialLoad, editKYC, uploadImg } = props;
  const classes = useStyles();
  const helpers = new Helpers();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue
  } = useForm();
   const cityAddress = watch("cityAddress");
   const isPHCurrent = watch("countryAddress") === "Philippines";

   const selectedProvince = PROVINCES.find(
     ({ name }) => name === watch("provinceAddress")
   );

   const cityValidity = CITIES.some(
     (item) =>
       item.province === selectedProvince?.key && item.name === cityAddress
   );
     useEffect(() => {
       if (!cityValidity && cityAddress) {
         setValue("cityAddress", "");
       }
     }, [cityValidity]);


  useEffect(() => {
    const subscription = watch((value) => {
      props.handleChangeData(value);
    });

    return () => subscription.unsubscribe();
  }, [watch]);



  if (initialLoad) {
    return <div>Loading...</div>;
  }
  const onSubmit = async (data) => {
    props.nextStep();
    props.fixedStep(2);
    console.log(data);
  };

    const notice = (
      <>
        <b>Did you already submit a primary government-issued ID? </b>{" "}
        <span>
          If not, kindly prepare a primary or secondary ID: Primary ID
          Examples: Driver&#39;s License, Passport, National ID, Voter&#39;s ID,
          Postal ID, UMID Secondary ID Examples: Company ID, School ID, TIN ID
        </span>
      </>
    );

  const noticeBar = (
    <Paper
      style={{
        border: "3px",
        borderColor: "#FFD966",
        backgroundColor: "#FFD966",
        borderStyle: "solid",
        borderRadius: "5px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          padding: "10px",
        }}
      >
        {notice}
      </div>
    </Paper>
  );

  const filterCitiesByProv = CITIES.filter(
    (item) => item.province === selectedProvince?.key
  );

  return (
    <Fade in={true}>
      <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {(additionalInfo.isPrimaryId == null ||
            additionalInfo.isPrimaryId === false) && (
            <>
              {noticeBar}
              <div className="mx-0 lg:mx-5 border-solid border-2 px-3 lg:px-10 pb-5">
                <Grid item xs={12}>
                  <h1 className="text-center">
                    Additional Identification Documents
                  </h1>
                </Grid>
                <Grid>
                  <div style={{ marginBottom: "20px" }}>
                    <p style={{ fontWeight: "600" }}>
                      In order to comply with the Anti-Money Laundering Act
                      (AMLA),{process.env.REACT_APP_PROJECT_NAME} requires all
                      its clients to complete a verification process. Kindly
                      upload the frontside and backside of any valid
                      primary/secondary Philippine government-issued IDs.
                      (Primary ID Examples: Driver&#39;s License, Passport,
                      National ID, Voter&#39;s ID, Postal ID, UMID. Secondary ID
                      Examples: Company ID, School ID, TIN ID).
                    </p>

                    <p style={{ fontWeight: "600" }}>
                      Note: Please upload 2 photos. One front and one back of
                      the valid ID. Keep your signature visible.
                    </p>
                  </div>
                </Grid>
                <Grid container spacing={2}>
                  {editIdPhotoFrontSwitch(editKYC, uploadImg, classes, props)}
                  {editIdPhotoBackSwitch(editKYC, uploadImg, classes, props)}
                </Grid>
              </div>
            </>
          )}
          <Grid item xs={12}>
            <h1 className="text-center">Additional Details</h1>
          </Grid>

          <Divider />
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.margin}
          >
            <label className="dark-label-short">Primary Occupation</label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="primaryoccupation"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Occupation"
              type="text"
              className={classes.margin}
              value={additionalInfo.work.primaryOccupation}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.margin}
          >
            <label className="dark-label-short">
              Name of Company or Employer
            </label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="nameOfEmployer"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Employer/Company Name"
              type="text"
              className={classes.margin}
              value={additionalInfo.work.nameOfEmployer}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.margin}
          >
            <label className="dark-label-short">Nature of Bussiness</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Nature of Business"
              name="businessNature"
              value={additionalInfo.work.businessNature}
              className={classes.drop}
              options={natureOfBusinessList}
            />
          </Grid>

          <div className="mx-0 lg:mx-5 py-0 lg:py-5 border-solid border-2 px-10 pb-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h1 className="text-center">Business Address</h1>
              </Grid>
              <Grid item xs={12} lg={4} className={classes.margin}>
                <label className="dark-label-short">
                  Country of Work or Business
                </label>
                <SelectFormAddress
                  disabled={false}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  rules={{ required: "This field is required" }}
                  inputProps={{ style: { padding: "12px" } }}
                  placeholder="Select Country of work/business"
                  name="countryAddress"
                  value={
                    additionalInfo.businessAddress.country !== "" &&
                    COUNTRIES.find(
                      ({ name }) =>
                        name === additionalInfo.businessAddress.country
                    )
                  }
                  className={classes.drop}
                  options={COUNTRIES}
                />
              </Grid>
              <Grid item xs={12} lg={4} className={classes.margin}>
                <label className="dark-label-short">
                  Province or Equivalent
                </label>
                {isPHCurrent ? (
                  <SelectFormAddress
                    disabled={additionalInfo.businessAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="Select Province of work"
                    name="provinceAddress"
                    value={
                      additionalInfo.businessAddress.province !== null &&
                      PROVINCES.find(
                        ({ name }) =>
                          name === additionalInfo.businessAddress.province
                      )
                    }
                    className={classes.drop}
                    options={PROVINCES}
                  />
                ) : (
                  <TextForm
                    disabled={additionalInfo.businessAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    placeholder="Input your province of work"
                    name="provinceAddress"
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    type="text"
                    className={classes.margin}
                    value={
                      watch("countryAddress") === "Philippines"
                        ? ""
                        : additionalInfo.businessAddress.province
                    }
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4} className={classes.margin}>
                <label className="dark-label-short">
                  City, Municipality or Equivalent
                </label>
                {isPHCurrent ? (
                  <SelectFormAddress
                    disabled={additionalInfo.businessAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="Select City or Municipality of work"
                    name="cityAddress"
                    value={
                      additionalInfo.businessAddress.province !== null &&
                      CITIES.find(
                        ({ name }) =>
                          name === additionalInfo.businessAddress.city
                      )
                    }
                    className={classes.drop}
                    options={filterCitiesByProv}
                  />
                ) : (
                  <TextForm
                    disabled={additionalInfo.businessAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    placeholder="Input your City or Municipality of work"
                    name="cityAddress"
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    type="text"
                    className={classes.margin}
                    value={
                      watch("countryAddress") === "Philippines"
                        ? ""
                        : additionalInfo.businessAddress.city
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={8} className={classes.margin}>
                <label className="dark-label-short">
                  Street, Subdivision, Barangay or Equivalent
                </label>
                <TextForm
                  disabled={additionalInfo.businessAddress.country === ""}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  placeholder="Input your Street, Subdivision, Barangay or equivalent for business"
                  name="street"
                  rules={{ required: "This field is required" }}
                  inputProps={{ style: { padding: "12px" } }}
                  type="text"
                  className={classes.margin}
                  value={additionalInfo.businessAddress.street}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.margin}>
                <label className="dark-label-short">ZIP Code</label>
                <TextForm
                  disabled={additionalInfo.businessAddress.country === ""}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  placeholder="Input zip code"
                  name="zip"
                  rules={{
                    required: "This field is required",
                    validate: (value) =>
                      value.length === 4 || "Please input correct Zip Code",
                  }}
                  inputProps={{ style: { padding: "12px" } }}
                  type="number"
                  className={classes.margin}
                  value={additionalInfo.businessAddress.zip}
                />
              </Grid>
            </Grid>
          </div>
          <br />
          {additionalInfo.existingID && (
            <>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <label className="dark-label-short">Select Your ID</label>
                <SelectForm
                  disabled={false}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  rules={{ required: "This field is required" }}
                  inputProps={{ style: { padding: "12px" } }}
                  placeholder="Choose One"
                  name="selectedIDs"
                  value={additionalInfo.ids.idType}
                  className={classes.drop}
                  options={optionSelectedID}
                />
              </Grid>
              {watch("selectedIDs") === "TIN" ? (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <label className="dark-label-short">
                  Tax Identification Number (TIN)
                  </label>
                  <TextForm
                    disabled={false}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    name="TIN"
                    rules={{
                      required: "This field is required",
                      validate: (value) => {
                        if (value.length > 12) {
                          return "Maximum of 12 digits";
                        }
                        if (value.length < 9) {
                          return "Minimum of 9 digits";
                        }
                      },
                    }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="Tax Identification Number (TIN)"
                    type="number"
                    className={classes.margin}
                    value={additionalInfo.ids.TIN}
                  />
                </Grid>
              ) : watch("selectedIDs") === "SSS" ? (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <label className="dark-label-short">SSS/GSIS Number</label>
                  <TextForm
                    disabled={false}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    name="SSS"
                    rules={{
                      required: "This field is required",

                      validate: (value) => {
                        if (value.length > 10) {
                          return "Minimum of 10 digits";
                        }
                      },
                    }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="SSS Number / GSIS Number"
                    type="number"
                    className={classes.margin}
                    value={additionalInfo.ids.SSS}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}></Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <label className="dark-label-short">Annual Income</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Annual Income"
              name="annualIncomeAmount"
              value={additionalInfo.work.annualIncomeAmount}
              className={classes.drop}
              options={optionSelectedAnnualIncome}
            />
          </Grid>
          <Grid item lg={12}></Grid>
        </Grid>
        <Divider style={{ marginTop: "60px", marginBottom: "60px" }} />
        <Grid style={{ marginTop: "30px" }} item xs={12}>
          <div className="relative grid lg:mr-4 my-5 lg:my-0 lg:justify-items-end ">
            <CustomButton
              text="Continue"
              type="submit"
              disabled={false}
              buttonTheme="primary"
            />
          </div>
        </Grid>
      </ValidatorForm>
    </Fade>
  );
};



export default ClientInformation;


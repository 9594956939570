import {
  Divider,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
  FormControlLabel,
  Radio,
  withStyles,
} from "@material-ui/core";
import React, { Component, useEffect, useMemo, useState } from "react";
import CustomButton from "../../Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Helpers from "../../Helpers";
import MobileNumberField from "../../ValidatedFields/MobileNumberField";
import DropDownField from "../../ValidatedFields/DropDownField";
import { shortOccupation, sourceIncome } from "./constants";
import { useForm } from "react-hook-form";
import { optionCivilStatus, optionGender, optionNationality, optionSelectedID } from "./constants";
import COUNTRIES from "../../PRPCM/Countries";
import { RadioForm, SelectForm, SelectFormAddress, TextForm, editIdPhotoBackSwitch, editIdPhotoFrontSwitch, editSelfieSwitch } from "./fieldAndimage";
import PROVINCES from "../../PRPCM/Provinces";
import CITIES from "../../PRPCM/Cities";

const AccSummary = withStyles((theme) => ({
  root: {
    "& .MuiAccordionSummary-expandIcon": {
      transform: "none",
    },
    "& .MuiTouchRipple-root": {
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      overflow: "hidden",
      position: "relative",
      borderRadius: "0px",
      pointerEvents: "none",
      backgroundColor: "transparent", 
    },
  },
}))(AccordionSummary);

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "10px",
  },
  ml10px: {
    marginLeft: "40px",
  },
  mb30px: {
    marginTop: "60px",
    marginBottom: "60px",
  },
  drop: {
    marginTop: "9px",
    height: "45px",
  },
}));

const ClientInformation = (props) => {
  const { clientInfo, initialLoad, editKYC, uploadImg } = props;

  const classes = useStyles();
  const helpers = new Helpers();

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm();

  const cityAddress = watch("cityAddress");
  const isPHCurrent = watch("countryAddress") === "Philippines";

  const selectedProvince = PROVINCES.find(
    ({ name }) => name === watch("provinceAddress")
  );

  const cityValidity = CITIES.some(
    (item) =>
      item.province === selectedProvince?.key && item.name === cityAddress
  );
  useEffect(() => {
    if (!cityValidity && cityAddress) {
      setValue("cityAddress", "");
    }
  }, [cityValidity]);

  useEffect(() => {
    const subscription = watch((value) => {
      props.handleChangeData(value);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const mariageSelection =
    props.mariageOption === "" || props.mariageOption.mariageOption === ""; 
  if (initialLoad) {
    return <div>Loading...</div>;
  }

  const onSubmit = async (data) => {
    props.nextStep();
    props.fixedStep(2);
    console.log(data);
  };

  const notice = (
    <>
      <b>Please prepare one (1) primary valid ID for your Client Application. </b>{" "}
      <span>
        Examples: Driver&#39;s License, Passport, National ID, Voter&#39;s ID,
        Postal ID, UMID. If you don&#39;t have any of the above, the following
        are also accepted: Philhealth ID, SSS ID , TIN ID, PRC ID, and School ID
        (for students only).
      </span>
    </>
  );

  const noticeBar = (
    <Paper
      style={{
        border: "3px",
        borderColor: "#FFD966",
        backgroundColor: "#FFD966",
        borderStyle: "solid",
        borderRadius: "5px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          padding: "10px",
        }}
      >
        {notice}
      </div>
    </Paper>
  );

  const filterCitiesByProv = CITIES.filter(
    (item) => item.province === selectedProvince?.key
  );

  const numberLimit = helpers.phoneNumIsFromPH(clientInfo.mobileNumber)
    ? ["required", "minStringLength:12", "maxStringLength:12"]
    : ["required", "minStringLength:4"];

  const errorNumberLimit = helpers.phoneNumIsFromPH(clientInfo.mobileNumber)
    ? ["This field is required.", "Must be 12 digits", "Must be 12 digits"]
    : ["This field is required.", "Must be in correct format."];

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
        {noticeBar}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 className="text-center">Client Information</h1>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.margin}
          >
            <label className="dark-label-short">First Name</label>
            <TextForm
              disabled={
                !editKYC || editKYC === "" || editKYC === null ? false : true
              }
              trigger={trigger}
              error={errors}
              control={control}
              name="firstName"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Your first name"
              type="text"
              className={classes.margin}
              value={clientInfo.name.firstName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.margin}
          >
            <label className="dark-label-short">Middle Name</label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="middleName"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Your middle name"
              type="text"
              className={classes.margin}
              value={clientInfo.name.middleName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className={classes.margin}
          >
            <label className="dark-label-short">Last Name</label>
            <TextForm
              disabled={
                !editKYC || editKYC === "" || editKYC === null ? false : true
              }
              trigger={trigger}
              error={errors}
              control={control}
              name="lastName"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Your last name"
              type="text"
              className={classes.margin}
              value={clientInfo.name.lastName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            className={classes.margin}
          >
            <label className="dark-label-short">Suffix</label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="suffix"
              rules={{ required: false }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Suffix"
              type="text"
              className={classes.margin}
              value={clientInfo.name.suffix}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className={classes.margin}
          >
            <label className="dark-label-short">Email Address</label>
            <TextForm
              disabled={true}
              trigger={trigger}
              error={errors}
              name="email"
              control={control}
              rules={{
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address.",
                },
              }}
              placeholder="Enter email address"
              className={classes.margin}
              type="email"
              inputProps={{ style: { padding: "12px" } }}
              value={clientInfo.email}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className={classes.margin}
          >
            <label className="dark-label-short">Mobile Number</label>
            <MobileNumberField
              className={classes.margin}
              name="mobileNumber"
              value={clientInfo.mobileNumber}
              onChange={props.handleMobileNumber}
              validators={numberLimit}
              errorMessages={errorNumberLimit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label className="dark-label-short">Date of Birth</label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="dateOfBirth"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Suffix"
              type="date"
              className={classes.margin}
              value={clientInfo.dateOfBirth}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <label className="dark-label-short">Gender</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Gender"
              name="genderInput"
              value={clientInfo.gender}
              className={classes.drop}
              options={optionGender}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <label className="dark-label-short">Civil Status</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Civil Status"
              name="civilStatusInput"
              value={clientInfo.civil.civilStatus}
              className={classes.drop}
              options={optionCivilStatus}
            />
          </Grid>
          {watch("civilStatusInput") === "married" ? (
            <Accordion
              expanded={props.mariageOption === "yes"}
              style={{ width: "100%", marginBottom: "15px" }}
            >
              <AccSummary
                expandIcon={
                  <>
                    <FormControlLabel
                      control={
                        <Radio
                          {...register("mariageOption", {
                            required: "This field is required",
                          })}
                          checked={props.mariageOption === "no"}
                          onChange={props.handleMarriage}
                          value="no"
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          {...register("mariageOption", {
                            required: "This field is required",
                          })}
                          checked={props.mariageOption === "yes"}
                          onChange={props.handleMarriage}
                          value="yes"
                        />
                      }
                      label="No"
                    />
                    {mariageSelection && errors.mariageOption && (
                      <p className="absolute text-red-600 text-xs top-8 left-6">
                        This field is required
                      </p>
                    )}
                  </>
                }
              >
                <b>Does your married name match your name on your valid ID?</b>
              </AccSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: "100%",
                    display: props.mariageOption === "no" ? "none" : "block",
                  }}
                >
                  <DropDownField
                    onAdd={props.handleDropzone("marriageCert")}
                    onDelete={props.handleDropzoneDelete("marriageCert")}
                    filesLimit={1}
                    value={uploadImg.marriageCertLabel}
                    image={uploadImg.marriageCertPreview}
                    {...(props.mariageOption === "yes"
                      ? {
                          validators: ["required"],
                          errorMessages: ["This field is required."],
                        }
                      : {})}
                  />
                  {editKYC && uploadImg.oldMarriageCert !== null && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={() =>
                        window.open(
                          uploadImg.oldMarriageCert,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                      fullWidth
                    >
                      View my current photo
                    </Button>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label className="dark-label-short">City of Birth</label>
            <TextForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="cityOfBirth"
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="City of Birth"
              type="text"
              className={classes.margin}
              value={clientInfo.cityOfBirth}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <label className="dark-label-short">Country of Birth</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Country of Birth"
              name="countryBirth"
              value={clientInfo.placeOfBirth}
              className={classes.drop}
              options={COUNTRIES}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{ marginBottom: "20px" }}
          >
            <label className="dark-label-short">Nationality</label>

            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Nationality"
              name="nationalityInput"
              value={clientInfo.nationality}
              className={classes.drop}
              options={optionNationality}
            />
          </Grid>
          <div className="mx-0 lg:mx-5 py-0 lg:py-5 border-solid border-2 px-10 pb-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h1 className="text-center">Current Address</h1>
              </Grid>
              <Grid item xs={12} lg={4} className={classes.margin}>
                <label className="dark-label-short">Country</label>
                <SelectFormAddress
                  disabled={false}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  rules={{ required: "This field is required" }}
                  inputProps={{ style: { padding: "12px" } }}
                  placeholder="Input your current country of residence"
                  name="countryAddress"
                  value={
                    clientInfo.currentAddress.country !== "" &&
                    COUNTRIES.find(
                      ({ name }) => name === clientInfo.currentAddress.country
                    )
                  }
                  className={classes.drop}
                  options={COUNTRIES}
                />
              </Grid>
              <Grid item xs={12} lg={4} className={classes.margin}>
                <label className="dark-label-short">
                  Province or Equivalent
                </label>
                {isPHCurrent ? (
                  <SelectFormAddress
                    disabled={clientInfo.currentAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="Province or Equivalent Address"
                    name="provinceAddress"
                    value={
                      clientInfo.currentAddress.province !== null &&
                      PROVINCES.find(
                        ({ name }) =>
                          name === clientInfo.currentAddress.province
                      )
                    }
                    className={classes.drop}
                    options={PROVINCES}
                  />
                ) : (
                  <TextForm
                    disabled={clientInfo.currentAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    placeholder="Input your Province or Equivalent Address"
                    name="provinceAddress"
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    type="text"
                    className={classes.margin}
                    value={
                      watch("countryAddress") === "Philippines"
                        ? ""
                        : clientInfo.currentAddress.province
                    }
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={4} className={classes.margin}>
                <label className="dark-label-short">
                  City, Municipality or Equivalent
                </label>
                {isPHCurrent ? (
                  <SelectFormAddress
                    disabled={clientInfo.currentAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    placeholder="Current city, municipality of residence"
                    name="cityAddress"
                    value={
                      clientInfo.currentAddress.province !== null &&
                      CITIES.find(
                        ({ name }) => name === clientInfo.currentAddress.city
                      )
                    }
                    className={classes.drop}
                    options={filterCitiesByProv}
                  />
                ) : (
                  <TextForm
                    disabled={clientInfo.currentAddress.country === ""}
                    trigger={trigger}
                    error={errors}
                    control={control}
                    placeholder="Input your current city, municipality of residence"
                    name="cityAddress"
                    rules={{ required: "This field is required" }}
                    inputProps={{ style: { padding: "12px" } }}
                    type="text"
                    className={classes.margin}
                    value={
                      watch("countryAddress") === "Philippines"
                        ? ""
                        : clientInfo.currentAddress.city
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={8} className={classes.margin}>
                <label className="dark-label-short">
                  Street, Subdivision, Barangay or Equivalent
                </label>
                <TextForm
                  disabled={clientInfo.currentAddress.country === ""}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  placeholder="Input your street, subdivision, barangay or equivalent of residence"
                  name="street"
                  rules={{ required: "This field is required" }}
                  inputProps={{ style: { padding: "12px" } }}
                  type="text"
                  className={classes.margin}
                  value={clientInfo.currentAddress.street}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.margin}>
                <label className="dark-label-short">ZIP Code</label>
                <TextForm
                  disabled={clientInfo.currentAddress.country === ""}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  placeholder="Input zip code"
                  name="zip"
                  rules={{
                    required: "This field is required",
                    validate: (value) =>
                      value.length === 4 || "Please input correct Zip Code",
                  }}
                  inputProps={{ style: { padding: "12px" } }}
                  type="number"
                  className={classes.margin}
                  value={clientInfo.currentAddress.zip}
                />
              </Grid>
            </Grid>
          </div>
          <br />
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label className="dark-label-short">Select Your ID</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Choose One"
              name="selectedIDs"
              value={clientInfo.ids.idType}
              className={classes.drop}
              options={optionSelectedID}
            />
          </Grid>
          {watch("selectedIDs") === "TIN" ? (
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <label className="dark-label-short">
              Tax Identification Number (TIN)
              </label>
              <TextForm
                disabled={false}
                trigger={trigger}
                error={errors}
                control={control}
                name="TIN"
                rules={{
                  required: "This field is required",
                  validate: (value) => {
                    if (value.length > 12) {
                      return "Maximum of 12 digits";
                    }
                    if (value.length < 9) {
                      return "Minimum of 9 digits";
                    }
                  },
                }}
                inputProps={{ style: { padding: "12px" } }}
                placeholder="Tax Identification Number (TIN)"
                type="number"
                className={classes.margin}
                value={clientInfo.ids.TIN}
              />
            </Grid>
          ) : (
            watch("selectedIDs") === "SSS" && (
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <label className="dark-label-short">SSS/GSIS Number</label>
                <TextForm
                  disabled={false}
                  trigger={trigger}
                  error={errors}
                  control={control}
                  name="SSS"
                  rules={{
                    required: "This field is required",

                    validate: (value) => {
                      if (value.length > 10) {
                        return "Minimum of 10 digits";
                      }
                    },
                  }}
                  inputProps={{ style: { padding: "12px" } }}
                  placeholder="SSS Number / GSIS Number"
                  type="number"
                  className={classes.margin}
                  value={clientInfo.ids.SSS}
                />
              </Grid>
            )
          )}
          {!watch("selectedIDs")  &&
          <Grid item xs={12}>
            <span className="text-sm font-light ">
              Note: If you don&#39;t have any TIN, SSS, or GSIS, you will be
              unable to invest in select funds. You may submit this later on.
            </span>
          </Grid>}
          <Grid item lg={12}></Grid>
          <Grid item xs={12} lg={6}>
            <label className="dark-label-short">Primary Source of Income</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Source of Income"
              name="sourceIncome"
              value={clientInfo.work.sourceOfFunds}
              className={classes.drop}
              options={sourceIncome}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label className="dark-label-short">Primary Occupation</label>
            <SelectForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              rules={{ required: "This field is required" }}
              inputProps={{ style: { padding: "12px" } }}
              placeholder="Select Primary Occupation"
              name="primaryoccupation"
              value={clientInfo.work.primaryOccupation}
              className={classes.drop}
              options={shortOccupation}
            />
          </Grid>
          <div className="mx-0 lg:mx-5 border-solid border-2 px-3 lg:px-10 pb-3">
            <Grid item xs={12}>
              <h1 className="text-center">Identification Documents</h1>
            </Grid>
            <Grid>
              <div style={{ marginBottom: "20px" }}>
                <p style={{ fontWeight: "600" }}>
                  In order to comply with the Anti-Money Laundering Act (AMLA),{" "}
                  {process.env.REACT_APP_PROJECT_NAME} requires all its clients
                  to complete a verification process. Kindly upload the
                  frontside and backside of any valid primary Philippine
                  government-issued IDs. (Ex. Driver&#39;s License, Passport,
                  National ID, Voter&#39;s ID, Postal ID, UMID). If you
                  don&#39;t have any of the above, the following are also
                  accepted: Philhealth ID, SSS ID , TIN ID, PRC ID, and School
                  ID (for students only).
                </p>
                <p style={{ fontWeight: "600" }}>
                  Note: Please upload 2 photos. One front and one back of the
                  valid ID. Keep your signature visible.
                </p>
              </div>
            </Grid>
            <Grid container spacing={2}>
              {editIdPhotoFrontSwitch(editKYC, uploadImg, classes, props)}
              {editIdPhotoBackSwitch(editKYC, uploadImg, classes, props)}
            </Grid>
            {editSelfieSwitch(editKYC, uploadImg, classes, props)}
          </div>
        </Grid>
        <Divider style={{ marginTop: "60px", marginBottom: "60px" }} />
        <Grid style={{ marginTop: "30px" }} item xs={12}>
          <div className="relative grid lg:mr-4 my-5 lg:my-0 lg:justify-items-end ">
            <CustomButton
              text="Continue"
              type="submit"
              disabled={false}
              buttonTheme="primary"
            />
          </div>
        </Grid>
      </ValidatorForm>
    </>
  );
};

export default ClientInformation;
